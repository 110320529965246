import { createTheme } from "@mui/material";
import { orange } from "@mui/material/colors";
import { breakpoints } from "./breakpoints";
import { darkModePalette, lightModePalette } from "./palette";
import { spacing } from "./spacing";
import { transitions } from "./transitions";
import { typography } from "./typography";

export enum ThemeVariantsProps {
  light = "light",
  dark = "dark",
}

declare module "@mui/material/styles" {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    c2color: true;
    c1color: true;
    b2color: true;
    b1color: true;
    a2color: true;
    a2fcolor: true;
    a1mcolor: true;
    pa1color: true;
    ylecolor: true;
    backgroundColor: true;
  }
}

export const theme = (mode: ThemeVariantsProps) => {
  return createTheme({
    palette: {
      mode,
      ...(mode === "light" ? lightModePalette : darkModePalette),
    },
    typography,
    spacing,
    breakpoints,
    transitions,
    status: {
      danger: orange[500],
    },
  });
};

export const borderRadiusStandard = "40px";
export const borderRadiusCircle = "50%";
