import { useQuery } from "@tanstack/react-query";
import { createContext, useState, useRef, useCallback } from "react";
import { authenticationService } from "../../services/authentication";
import type { CustomCognitoUser } from "../../services/authentication/types";
import type { AuthenticationContextProviderProps, AuthenticationContextValue } from "./types";

export const AuthenticationContext = createContext<AuthenticationContextValue | undefined>(undefined);

export const AuthenticationContextProvider = ({ children }: AuthenticationContextProviderProps) => {
  const [user, setUser] = useState<CustomCognitoUser>();
  const [userId, setUserId] = useState<string>("");
  const [schoolId, setSchoolId] = useState<string>("");
  const [distributorId, setDistributorId] = useState<string>("");
  const [role, setRole] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [profileName, setProfileName] = useState<string>("");
  const jwtToken = useRef<string | null>(null);

  const { isLoading: isDeterminingAuthenticationStatus } = useQuery<CustomCognitoUser>(
    ["getAuthenticatedUser"],
    () => authenticationService.getAuthenticatedUser(),
    {
      retry: false,
      onSuccess: (user) => {
        setUser(user);
        setUserId(user.attributes["custom:UserId"]);
        if (user.attributes["custom:Role"] === "DistributorAdmin" || user.attributes["custom:Role"] === "Agent") {
          setDistributorId(user.attributes["custom:DistributorId"]);
        } else {
          setSchoolId(user.attributes["custom:SchoolId"]);
        }
        setRole(user.attributes["custom:Role"]);
        setEmail(user.attributes.email);
        setProfileName(user.attributes.name);
      },
    },
  );

  const getJwtToken = useCallback(async () => {
    if (jwtToken.current) {
      return jwtToken.current;
    }
    const currentSession = await authenticationService.getCurrentSession();
    const idToken = currentSession.getIdToken();
    const token = idToken.getJwtToken();
    jwtToken.current = token;
    return token;
  }, []);

  const value = {
    isDeterminingAuthenticationStatus,
    user,
    setUser,
    getJwtToken,
    userId,
    setUserId,
    schoolId,
    setSchoolId,
    distributorId,
    setDistributorId,
    role,
    setRole,
    email,
    setEmail,
    profileName,
    setProfileName,
  };

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>;
};
