import { createContext, useEffect, useState } from "react";
import type { School, UserCompletedExercises, UserClassrooms, User, SchoolUsers } from "../../services/backend/types";
import type { ExerciseCounters } from "../../utils/BasicTypes";
import type { ClassroomMemberClassrooms } from "../ClassroomMembers/ClassroomMemberCard/types";
import type { ClassroomMember, CountClassroomCorrectionsValue, LicenseDurationAvailability } from "../../services/backend_client";
import type { AppContext } from "./types";

export const Ctx = createContext({} as AppContext);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ContextProvider = ({ children }: any) => {
  const [loading, setLoading] = useState(0);
  const [schoolInfo, setSchoolInfo] = useState<School>({} as School);
  const [exerciseCounters, setExerciseCounters] = useState({} as ExerciseCounters);
  const [userCompletedExercises, setUserCompletedExercises] = useState({} as UserCompletedExercises);
  const [myClassrooms, setMyClassrooms] = useState({} as UserClassrooms);
  const [mySchoolUsers, setMySchoolUsers] = useState({} as SchoolUsers);
  const [fetchAllClassrooms, setFetchAllClassrooms] = useState(false);
  const [userClassrooms, setUserClassrooms] = useState<ClassroomMemberClassrooms>({});
  const [userClassroomMembers, setUserClassroomMembers] = useState<ClassroomMember[]>([]);
  const [myAccountInfo, setMyAccountInfo] = useState<User>({} as User);
  const [licenses, setLicenses] = useState({} as { [key: string]: LicenseDurationAvailability });
  const [correctionsPending, setCorrectionsPending] = useState({} as { [key: string]: CountClassroomCorrectionsValue });

  const [value, setValue] = useState({
    loading: loading > 0,
    setLoading,
    schoolInfo,
    setSchoolInfo,
    exerciseCounters,
    setExerciseCounters,
    userCompletedExercises,
    setUserCompletedExercises,
    myClassrooms,
    setMyClassrooms,
    mySchoolUsers,
    setMySchoolUsers,
    fetchAllClassrooms,
    setFetchAllClassrooms,
    userClassrooms,
    setUserClassrooms,
    userClassroomMembers,
    setUserClassroomMembers,
    myAccountInfo,
    setMyAccountInfo,
    licenses,
    setLicenses,
    correctionsPending,
    setCorrectionsPending,
  });

  useEffect(() => {
    setValue((value) => ({
      ...value,
      loading: loading > 0,
      schoolInfo,
      exerciseCounters,
      userCompletedExercises,
      myClassrooms,
      mySchoolUsers,
      fetchAllClassrooms,
      userClassrooms,
      userClassroomMembers,
      myAccountInfo,
      licenses,
      correctionsPending,
    }));
  }, [
    loading,
    schoolInfo,
    exerciseCounters,
    userCompletedExercises,
    myClassrooms,
    mySchoolUsers,
    fetchAllClassrooms,
    userClassrooms,
    userClassroomMembers,
    myAccountInfo,
    licenses,
    correctionsPending,
  ]);

  return <Ctx.Provider value={value}>{children}</Ctx.Provider>;
};
