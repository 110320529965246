import { AnswerCorrection } from "../services/backend/types";
import type { TestScore } from "../services/backend/types";

export function buildQuestionId(exerciseId: string, questionId: number | string): string {
  return `${exerciseId}_q_${questionId}`;
}

export function buildWhyId(exerciseId: string, questionId: string) {
  return `${exerciseId}_w_${questionId}`;
}

export function checkIfCorrect(testScore: TestScore, textFieldKey: string, exerciseSent: boolean | undefined) {
  return exerciseSent ? testScore?.CorrectionResults?.[textFieldKey] === AnswerCorrection.CORRECT : undefined;
}
