import { lazy, Suspense } from "react";
import { useAuthenticationContext } from "../../hooks/context/useAuthenticationContext";
import { LoginBackdrop } from "../Basic/LoginBackdrop";

const AuthenticatedApplication = lazy(() =>
  import("../AuthenticatedApplication").then(({ AuthenticatedApplication }) => ({
    default: AuthenticatedApplication,
  })),
);

const UnAuthenticatedApplication = lazy(() =>
  import("../UnAuthenticatedApplication").then(({ UnAuthenticatedApplication }) => ({
    default: UnAuthenticatedApplication,
  })),
);

export const Application = () => {
  const { isDeterminingAuthenticationStatus, user } = useAuthenticationContext();

  if (isDeterminingAuthenticationStatus) {
    return <LoginBackdrop open={isDeterminingAuthenticationStatus} />;
  }

  return (
    <Suspense fallback={<LoginBackdrop open={isDeterminingAuthenticationStatus} />}>
      {user ? <AuthenticatedApplication /> : <UnAuthenticatedApplication />}
    </Suspense>
  );
};
