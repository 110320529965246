import { Backdrop, CircularProgress } from "@mui/material";
import type { LoginBackdropProps } from "./types";

export const LoginBackdrop = ({ open }: LoginBackdropProps) => {
  return (
    <Backdrop sx={{ color: "white", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
      <CircularProgress color="inherit" />{" "}
    </Backdrop>
  );
};
