import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useMemo, useState } from "react";
import { Toaster } from "react-hot-toast";
import { BrowserRouter } from "react-router-dom";
import { AuthenticationContextProvider } from "../../context/authentication";
import { ThemeVariantsProps, theme } from "../../theme";
import { ContextProvider } from "../ContextProvider";
import { storageAvailable } from "../../utils/LocalStorage";
import { TOAST_DURATION } from "../../utils/Constants";
import type { ProvidersProps } from "./types";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const Providers = ({ children }: ProvidersProps) => {
  const [mode] = useState<ThemeVariantsProps>(ThemeVariantsProps.light);
  const activeTheme = useMemo(() => {
    if (storageAvailable()) {
      const storedTheme = localStorage.getItem("EnglishExamsLabTheme");
      const resultTheme = theme(storedTheme ? ThemeVariantsProps[storedTheme as ThemeVariantsProps] : mode);
      resultTheme.typography.button.textTransform = "none";
      return resultTheme;
    } else {
      const resultTheme = theme(mode);
      resultTheme.typography.button.textTransform = "none";
      return resultTheme;
    }
  }, [mode]);

  // To be used to change the theme
  // const handleOnChange = useCallback(() => {
  //   setMode(mode === ThemeVariantsProps.light ? ThemeVariantsProps.dark : ThemeVariantsProps.light);
  //   localStorage.setItem("EnglishExamsLabTheme", ThemeVariantsProps[mode]); // use ThemeVariantsProps[mode] to convert the enum value to the string
  // }, [mode]);

  return (
    <>
      <Toaster
        toastOptions={{
          duration: TOAST_DURATION,
        }}
        position="top-center"
      />
      <BrowserRouter>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={activeTheme}>
            <ContextProvider>
              <AuthenticationContextProvider>{children}</AuthenticationContextProvider>
            </ContextProvider>
            {/*Let's leave it it for now (accessible through NotFoundPage). We should also store it in localStorage or user settings*/}
            {/*Switch to change the theme (Normal-Dark). Let's leave it it for now (accessible through NotFoundPage). We should also store it in localStorage or user settings*/}
            {/* <Switch onChange={handleOnChange} /> */}
          </ThemeProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </BrowserRouter>
    </>
  );
};
