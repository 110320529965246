import type { NumberDictionary, SectionsPerLevel, StringDictionary, PartsPerLevel, GradesPerLevel } from "./BasicTypes";
export const PRODUCT_DOMAIN_URL = "https://www.myexamslab.com/";
export const CAMBRIDGE_SCALE_URL = "https://www.cambridgeenglish.org/exams-and-tests/cambridge-english-scale/";

export const SCHOOL_BACKGROUND_IMAGE = "/images/backgrounds/login-background.png";
export const DEFAULT_SCHOOL_LOGO_URL = "/images/LogoEEL.png";
export const AUDIO_TEST = "/audios/audio-test.mp3";

export const DEFAULT_USER_PASSWORD = "123456";

export const TIME_OUT = 3000;

export const DEFAULT_CURRENCY = "EUR";

export const TOAST_DURATION = 6000;

export const enum ExceptionStatusCode {
  ValidationException = 400,
  UnauthorizedException = 401,
  AccessDeniedException = 403,
  ResourceNotFoundException = 404,
  ConflictException = 409,
  InternalServerException = 500,
}

export const enum ExceptionStatusMessage {
  LICENSE_NOT_VALID_ERROR_MESSAGE = "User does not have a valid license",
  EXERCISE_BLOCKED_ERROR_MESSAGE = "The exercise was blocked by a teacher",
}

export const PAGE_SIZE: NumberDictionary = {
  schoolUsers: 500,
  userPracticeExercises: 50,
  classroomMockExams: 20,
  classroomPracticeExercises: 40,
  classroomPracticeTests: 20,
  payments: 10,
  paymentLicenses: 10,
  orders: 10,
  distributorPayments: 10,
  distributorTeamMembers: 100,
  distributorClients: 50,
  distributorOrders: 20,
};

export const CODES_TO_MONTHS: StringDictionary = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const CODES_TO_MONTHS_SHORT: StringDictionary = {
  0: "Jan",
  1: "Feb",
  2: "Mar",
  3: "Apr",
  4: "May",
  5: "Jun",
  6: "Jul",
  7: "Aug",
  8: "Sep",
  9: "Oct",
  10: "Nov",
  11: "Dec",
};

export const enum UserTabs {
  REPORT = "report",
  ACTIVITIES = "activities",
  ANALYTICS = "analytics",
  SETTINGS = "settings",
}

export enum SectionCodes {
  RAU = "RAU",
  REA = "REA",
  RAW = "RAW",
  LIS = "LIS",
  WRI = "WRI",
  SPE = "SPE",
  USE = "USE",
}

export enum LevelCodes {
  C2P = "C2P",
  C1A = "C1A",
  B2F = "B2F",
  B2S = "B2S",
  B1P = "B1P",
  B1S = "B1S",
  A2K = "A2K",
  A2S = "A2S",
  A2F = "A2F",
  A1M = "A1M",
  PA1 = "PA1",
}

export const ALL_LEVELS = "ALL";

export enum PartCodes {
  P1 = "P1",
  P2 = "P2",
  P3 = "P3",
  P4 = "P4",
  P5 = "P5",
  P6 = "P6",
  P7 = "P7",
  P8 = "P8",
  P34 = "P34",
}

export const enum SpeakingExerciseConstants {
  VIDEO = "video",
  AUDIO_WITH_IMAGE = "audio_with_image",
  TIMER_WITH_IMAGE = "timer_with_image",
  TEST = "test",
  ALL = "all",
  DISCUSSION = "discussion",
  ONE = "1",
  TWO = "2",
}

export const enum RecordingConstants {
  INACTIVE = "inactive",
  RECORDING = "recording",
}

export const LEVELS_TO_TEXT: StringDictionary = {
  C2P: "C2 Proficiency",
  C1A: "C1 Advanced",
  B2F: "B2 First",
  B2S: "B2 First Sch.",
  B1P: "B1 Preliminary",
  B1S: "B1 Preliminary Sch.",
  A2K: "A2 Key",
  A2S: "A2 Key Sch.",
  A2F: "A2 Flyers",
  A1M: "A1 Movers",
  PA1: "Pre A1 Starters",
  ALL: "All levels",
};

export const LEVELS_TO_URL: StringDictionary = {
  C2P: "c2-proficiency",
  C1A: "c1-advanced",
  B2F: "b2-first",
  B2S: "b2-first-for-schools",
  B1P: "b1-preliminary",
  B1S: "b1-preliminary-for-schools",
  A2K: "a2-key",
  A2S: "a2-key-for-schools",
  A2F: "a2-flyers",
  A1M: "a1-movers",
  PA1: "pre-a1-starters",
};

export const LEVELS_TO_CODES: StringDictionary = {
  C2P: "CPE",
  C1A: "CAE",
  B2F: "FCE",
  B2S: "FCE S.",
  B1P: "PET",
  B1S: "PET S.",
  A2K: "KET",
  A2S: "KET S.",
  A2F: "YLE F",
  A1M: "YLE M",
  PA1: "YLE S",
};

export const LEVELS_TO_COLOR: StringDictionary = {
  C2P: "c2color",
  C1A: "c1color",
  B2F: "b2color",
  B2S: "b2color",
  B1P: "b1color",
  B1S: "b1color",
  A2K: "a2color",
  A2S: "a2color",
  A2F: "a2fcolor",
  A1M: "a1mcolor",
  PA1: "pa1color",
};

export const SECTIONS_TO_TEXT: StringDictionary = {
  RAU: "Reading & Use of English",
  WRI: "Writing",
  LIS: "Listening",
  SPE: "Speaking",
  REA: "Reading",
  RAW: "Reading & Writing",
  USE: "Use of English",
};

export const SECTIONS_TO_URL: StringDictionary = {
  RAU: "reading-use-of-english",
  WRI: "writing",
  LIS: "listening",
  SPE: "speaking",
  REA: "reading",
  RAW: "reading-writing",
  USE: "use-of-english",
};

export const PARTS_TO_TEXT: StringDictionary = {
  P1: "Part 1",
  P2: "Part 2",
  P3: "Part 3",
  P4: "Part 4",
  P5: "Part 5",
  P6: "Part 6",
  P7: "Part 7",
  P8: "Part 8",
  P9: "Part 9",
  P34: "Parts 3 & 4",
};

export const PARTS_TO_TEXT_SHORT: StringDictionary = {
  P1: "1",
  P2: "2",
  P3: "3",
  P4: "4",
  P5: "5",
  P6: "6",
  P7: "7",
  P8: "8",
  P9: "9",
  P34: "3 & 4",
};

export const PARTS_TO_URL: StringDictionary = {
  P1: "part-1",
  P2: "part-2",
  P3: "part-3",
  P4: "part-4",
  P5: "part-5",
  P6: "part-6",
  P7: "part-7",
  P8: "part-8",
  P9: "part-9",
  P34: "parts-3-4",
};

export const ACTIVITY_TYPE_TO_TEXT: StringDictionary = {
  PracticeExercises: "Practice Exercise",
  PracticeTests: "Practice Test",
  MockExams: "Mock Exam",
};

export const EXAM_SECTIONS_PER_LEVEL: SectionsPerLevel = {
  C2P: {
    RAU: "Reading & Use of English",
    WRI: "Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
  C1A: {
    RAU: "Reading & Use of English",
    WRI: "Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
  B2F: {
    RAU: "Reading & Use of English",
    WRI: "Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
  B2S: {
    RAU: "Reading & Use of English",
    WRI: "Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
  B1P: {
    REA: "Reading",
    WRI: "Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
  B1S: {
    REA: "Reading",
    WRI: "Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
  A2K: {
    RAW: "Reading & Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
  A2S: {
    RAW: "Reading & Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
  A2F: {
    RAW: "Reading & Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
  A1M: {
    RAW: "Reading & Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
  PA1: {
    RAW: "Reading & Writing",
    LIS: "Listening",
    SPE: "Speaking",
  },
};

export const PARTS_PER_LEVEL: PartsPerLevel = {
  C2P: {
    RAU: ["P1", "P2", "P3", "P4", "P5", "P6", "P7"],
    WRI: ["P1", "P2"],
    LIS: ["P1", "P2", "P3", "P4"],
    SPE: ["P1", "P2", "P3"],
  },
  C1A: {
    RAU: ["P1", "P2", "P3", "P4", "P5", "P6", "P7", "P8"],
    WRI: ["P1", "P2"],
    LIS: ["P1", "P2", "P3", "P4"],
    SPE: ["P1", "P2", "P34"],
  },
  B2F: {
    RAU: ["P1", "P2", "P3", "P4", "P5", "P6", "P7"],
    WRI: ["P1", "P2"],
    LIS: ["P1", "P2", "P3", "P4"],
    SPE: ["P1", "P2", "P34"],
  },
  B2S: {
    RAU: ["P1", "P2", "P3", "P4", "P5", "P6", "P7"],
    WRI: ["P1", "P2"],
    LIS: ["P1", "P2", "P3", "P4"],
    SPE: ["P1", "P2", "P34"],
  },
  B1P: {
    REA: ["P1", "P2", "P3", "P4", "P5", "P6"],
    WRI: ["P1", "P2"],
    LIS: ["P1", "P2", "P3", "P4"],
    SPE: ["P1", "P2", "P34"],
  },
  B1S: {
    REA: ["P1", "P2", "P3", "P4", "P5", "P6"],
    WRI: ["P1", "P2"],
    LIS: ["P1", "P2", "P3", "P4"],
    SPE: ["P1", "P2", "P34"],
  },
  A2K: {
    RAW: ["P1", "P2", "P3", "P4", "P5", "P6", "P7"],
    LIS: ["P1", "P2", "P3", "P4", "P5"],
    SPE: ["P1", "P2"],
  },
  A2S: {
    RAW: ["P1", "P2", "P3", "P4", "P5", "P6", "P7"],
    LIS: ["P1", "P2", "P3", "P4", "P5"],
    SPE: ["P1", "P2"],
  },
  A2F: {
    RAW: ["P1", "P2", "P3", "P4", "P5", "P6", "P7"],
    LIS: ["P1", "P2", "P3", "P4", "P5"],
    SPE: ["P1", "P2", "P3", "P4"],
  },
  A1M: {
    RAW: ["P1", "P2", "P3", "P4", "P5", "P6"],
    LIS: ["P1", "P2", "P3", "P4", "P5"],
    SPE: ["P1", "P2", "P3", "P4"],
  },
  PA1: {
    RAW: ["P1", "P2", "P3", "P4", "P5"],
    LIS: ["P1", "P2", "P3", "P4"],
    SPE: ["P1", "P2", "P3", "P4"],
  },
};

export const SUBSECTIONS_PER_LEVEL: PartsPerLevel = {
  C2P: {
    REA: ["P1", "P5", "P6", "P7"],
    USE: ["P2", "P3", "P4"],
  },
  C1A: {
    REA: ["P1", "P5", "P6", "P7", "P8"],
    USE: ["P2", "P3", "P4"],
  },
  B2F: {
    REA: ["P1", "P5", "P6", "P7"],
    USE: ["P2", "P3", "P4"],
  },
  B2S: {
    REA: ["P1", "P5", "P6", "P7"],
    USE: ["P2", "P3", "P4"],
  },
  A2K: {
    REA: ["P1", "P2", "P3", "P4", "P5"],
    WRI: ["P6", "P7"],
  },
  A2S: {
    REA: ["P1", "P2", "P3", "P4", "P5"],
    WRI: ["P6", "P7"],
  },
};

export const GRADE_PER_LEVEL: GradesPerLevel = {
  C2P: {
    REA: {
      MaxScore: 44,
      GradeA: 36,
      GradeB: 33,
      GradeC: 28,
      underneathLevel: 22,
      underneathLevelMin: 14,
    },
    USE: {
      MaxScore: 28,
      GradeA: 22,
      GradeB: 20,
      GradeC: 17,
      underneathLevel: 13,
      underneathLevelMin: 9,
    },
    LIS: {
      MaxScore: 30,
      GradeA: 24,
      GradeB: 22,
      GradeC: 18,
      underneathLevel: 14,
      underneathLevelMin: 10,
    },
    WRI: {
      MaxScore: 40,
      GradeA: 34,
      GradeB: 30,
      GradeC: 24,
      underneathLevel: 16,
      underneathLevelMin: 10,
    },
    SPE: {
      MaxScore: 75,
      GradeA: 66,
      GradeB: 58,
      GradeC: 45,
      underneathLevel: 30,
      underneathLevelMin: 17,
    },
  },
  C1A: {
    REA: {
      MaxScore: 50,
      GradeA: 43,
      GradeB: 40,
      GradeC: 32,
      underneathLevel: 23,
      underneathLevelMin: 17,
    },
    USE: {
      MaxScore: 28,
      GradeA: 23,
      GradeB: 21,
      GradeC: 16,
      underneathLevel: 11,
      underneathLevelMin: 8,
    },
    LIS: {
      MaxScore: 30,
      GradeA: 26,
      GradeB: 24,
      GradeC: 18,
      underneathLevel: 13,
      underneathLevelMin: 11,
    },
    WRI: {
      MaxScore: 40,
      GradeA: 34,
      GradeB: 21,
      GradeC: 24,
      underneathLevel: 16,
      underneathLevelMin: 10,
    },
    SPE: {
      MaxScore: 75,
      GradeA: 66,
      GradeB: 59,
      GradeC: 45,
      underneathLevel: 30,
      underneathLevelMin: 17,
    },
  },
  B2F: {
    REA: {
      MaxScore: 42,
      GradeA: 37,
      GradeB: 33,
      GradeC: 24,
      underneathLevel: 16,
      underneathLevelMin: 10,
    },
    USE: {
      MaxScore: 28,
      GradeA: 24,
      GradeB: 22,
      GradeC: 18,
      underneathLevel: 11,
      underneathLevelMin: 7,
    },
    LIS: {
      MaxScore: 30,
      GradeA: 27,
      GradeB: 24,
      GradeC: 18,
      underneathLevel: 12,
      underneathLevelMin: 8,
    },
    WRI: {
      MaxScore: 40,
      GradeA: 34,
      GradeB: 31,
      GradeC: 24,
      underneathLevel: 16,
      underneathLevelMin: 10,
    },
    SPE: {
      MaxScore: 60,
      GradeA: 54,
      GradeB: 48,
      GradeC: 36,
      underneathLevel: 24,
      underneathLevelMin: 14,
    },
  },
  B2S: {
    REA: {
      MaxScore: 42,
      GradeA: 37,
      GradeB: 33,
      GradeC: 24,
      underneathLevel: 16,
      underneathLevelMin: 10,
    },
    USE: {
      MaxScore: 28,
      GradeA: 24,
      GradeB: 22,
      GradeC: 18,
      underneathLevel: 11,
      underneathLevelMin: 7,
    },
    LIS: {
      MaxScore: 30,
      GradeA: 27,
      GradeB: 24,
      GradeC: 18,
      underneathLevel: 12,
      underneathLevelMin: 8,
    },
    WRI: {
      MaxScore: 40,
      GradeA: 34,
      GradeB: 31,
      GradeC: 24,
      underneathLevel: 16,
      underneathLevelMin: 10,
    },
    SPE: {
      MaxScore: 60,
      GradeA: 54,
      GradeB: 48,
      GradeC: 36,
      underneathLevel: 24,
      underneathLevelMin: 14,
    },
  },
  B1P: {
    REA: {
      MaxScore: 32,
      GradeA: 29,
      GradeB: 27,
      GradeC: 23,
      underneathLevel: 13,
      underneathLevelMin: 5,
    },
    LIS: {
      MaxScore: 25,
      GradeA: 23,
      GradeB: 22,
      GradeC: 18,
      underneathLevel: 16,
      underneathLevelMin: 11,
    },
    WRI: {
      MaxScore: 40,
      GradeA: 34,
      GradeB: 31,
      GradeC: 24,
      underneathLevel: 12,
      underneathLevelMin: 7,
    },
    SPE: {
      MaxScore: 30,
      GradeA: 27,
      GradeB: 24,
      GradeC: 18,
      underneathLevel: 12,
      underneathLevelMin: 7,
    },
  },
  B1S: {
    REA: {
      MaxScore: 32,
      GradeA: 29,
      GradeB: 27,
      GradeC: 23,
      underneathLevel: 13,
      underneathLevelMin: 5,
    },
    LIS: {
      MaxScore: 25,
      GradeA: 23,
      GradeB: 22,
      GradeC: 18,
      underneathLevel: 16,
      underneathLevelMin: 11,
    },
    WRI: {
      MaxScore: 40,
      GradeA: 34,
      GradeB: 31,
      GradeC: 24,
      underneathLevel: 12,
      underneathLevelMin: 7,
    },
    SPE: {
      MaxScore: 30,
      GradeA: 27,
      GradeB: 24,
      GradeC: 18,
      underneathLevel: 12,
      underneathLevelMin: 7,
    },
  },
  A2K: {
    REA: {
      MaxScore: 30,
      GradeA: 28,
      GradeB: 26,
      GradeC: 20,
      underneathLevel: 13,
      underneathLevelMin: 7,
    },
    LIS: {
      MaxScore: 25,
      GradeA: 23,
      GradeB: 21,
      GradeC: 17,
      underneathLevel: 11,
      underneathLevelMin: 6,
    },
    WRI: {
      MaxScore: 30,
      GradeA: 26,
      GradeB: 24,
      GradeC: 18,
      underneathLevel: 12,
      underneathLevelMin: 8,
    },
    SPE: {
      MaxScore: 45,
      GradeA: 41,
      GradeB: 37,
      GradeC: 27,
      underneathLevel: 18,
      underneathLevelMin: 10,
    },
  },
  A2S: {
    REA: {
      MaxScore: 30,
      GradeA: 28,
      GradeB: 26,
      GradeC: 20,
      underneathLevel: 13,
      underneathLevelMin: 7,
    },
    LIS: {
      MaxScore: 25,
      GradeA: 23,
      GradeB: 21,
      GradeC: 17,
      underneathLevel: 11,
      underneathLevelMin: 6,
    },
    WRI: {
      MaxScore: 30,
      GradeA: 26,
      GradeB: 24,
      GradeC: 18,
      underneathLevel: 12,
      underneathLevelMin: 8,
    },
    SPE: {
      MaxScore: 45,
      GradeA: 41,
      GradeB: 37,
      GradeC: 27,
      underneathLevel: 18,
      underneathLevelMin: 10,
    },
  },
  A2F: {
    RAW: {
      MaxScore: 48,
    },
    LIS: {
      MaxScore: 25,
    },
    SPE: {
      MaxScore: 15,
    },
  },
  A1M: {
    RAW: {
      MaxScore: 39,
    },
    LIS: {
      MaxScore: 25,
    },
    SPE: {
      MaxScore: 15,
    },
  },
  PA1: {
    RAW: {
      MaxScore: 25,
    },
    LIS: {
      MaxScore: 20,
    },
    SPE: {
      MaxScore: 15,
    },
  },
};

export const IMAGE_SECTIONS: StringDictionary = {
  RAU: "/images/section-rea.png",
  WRI: "/images/section-wri.png",
  LIS: "/images/section-lis.png",
  SPE: "/images/section-spe.png",
  REA: "/images/section-rea.png",
  RAW: "/images/section-rea.png",
  USE: "/images/section-rea.png",
};

export const INDEX_TO_QUESTIONS: StringDictionary = {
  0: "A",
  1: "B",
  2: "C",
  3: "D",
  4: "E",
  5: "F",
  6: "G",
  7: "H",
  8: "I",
  9: "J",
  10: "K",
  11: "L",
  12: "M",
  13: "N",
  14: "O",
  15: "P",
  16: "Q",
  17: "R",
  18: "S",
  19: "T",
  20: "U",
  21: "V",
  22: "W",
  23: "X",
  24: "Y",
  25: "Z",
};

export const INDEX_TO_PARTS: StringDictionary = {
  0: "P1",
  1: "P2",
  2: "P3",
  3: "P4",
  4: "P5",
  5: "P6",
  6: "P7",
  7: "P8",
};

export const MOCKS_PER_LEVEL: NumberDictionary = {
  C2P: 3,
  C1A: 3,
  B2F: 3,
  B2S: 3,
  B1P: 3,
  B1S: 3,
  A2K: 3,
  A2S: 3,
  //A2F: 3,
  //A1M: 3,
  //PA1: 3
};

export const CURRENCY_TO_SYMBOL: StringDictionary = {
  EUR: "€",
  USD: "$",
};

export const EXCLUDED_LICENSES = ["M10"];

//TODO REMOVE ALL THIS FAKE DATA

export const LICENSE_CONFIG: LicenseConfigSettings = {
  AcquireLicenses: true,
  Prices: {
    M3: 15,
    M6: 25,
    M9: 35,
    M12: 45,
  },
};

interface LicenseConfigSettings {
  AcquireLicenses: boolean;
  Prices: {
    [key: string]: number;
  };
}
